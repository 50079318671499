<template>
  <div>
    <section class="main text-white" style="margin-top: -15px">
      <div class="home-section d-flex flex-column flex-md-row mb-5">
        <div
          class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start p-3"
        >
          <h1 class="f-secondary t-secondary p-2">
            Make your health career a reality in the UAE
          </h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            Welcome to the brand-new platform built for you and your career in
            the UAE. Whether you’re a physician, nurse, or allied health
            professional, you’ll get access to relevant opportunities instantly
            - so you can spend less time searching, and more time advancing your
            international health and social care career.
          </div>
          <div class="d-flex justify-content-start p-2">
            <button class="btn btn-primary" @click="goToRegister()">Register Now</button>
          </div>
        </div>
        <div class="col-12 col-md-6 p-0">
          <img
            src="../../../public/img/stm/landing-img.png"
            class="img-fluid"
          />
        </div>
      </div>
    </section>
    <RegisterSection />

    <!-- Benefits Section  -->
    <section class="main my-md">
      <h5 class="text-second text-center">BENEFITS</h5>
      <div class="text-center">
        <h2 class="f-secondary text-gradient-2">
          One platform. A world of health career opportunities
        </h2>
        <p>
          We’ve created the first global healthcare careers marketplace,
          designed to bring clarity and consistency to your healthcare career
          journey. With TalentFind, you’ll find everything you need to build
          your career in one place – bringing you opportunities to advance your
          career today and in the future.
        </p>
      </div>

      <div class="col-md-12 col-sm-11 mx-auto">
        <div class="row gx-5 mt-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Create your digital CV
            </p>
            Your TalentFind profile will help recruiters and organisations see
            all your skills, experience and education history instantly.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Secure document storage
            </p>
            Safely store all your health career documents in one place for the
            whole of your career.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Language training and upskilling
            </p>
            We’ve partnered with leading language training schools and
            universities to bring you access to all the resources you need to
            develop your skills.
          </div>
        </div>
        <div class="row gx-5 mt-md-5">
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Access a global job board
            </p>
            Explore health and social care opportunities locally and all over
            the world. TalentFind connects you with regional and international
            roles that suit your experience.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Guidance with your career pathway
            </p>
            Because we have a global knowledge of health training pathways, we
            can support you on your journey to growing your career.
          </div>
          <div class="col-lg-4 text-center mb-3">
            <div class="mb-2">
              <img
                width="64"
                height="63"
                src="../../../public/img/stm/tick_mark.svg"
              />
            </div>
            <p class="f-secondary font-25 bold text-dark-primary">
              Simplify your health career
            </p>
            Where other platforms make managing your health career feel
            complicated, we keep things simple – just one easy-to-use platform
            dedicated to your future in healthcare.
          </div>
        </div>
      </div>
    </section>
    <!-- Benefits Section Ends -->
    <VideoSection />
    <OpportunitiesSection />
    <ArticleSection />
    <FutureSection />
    <NewFooter />
  </div>
</template>

<script>
import HomeSection from "./HomeSection.vue";
import RegisterSection from "./RegisterSection.vue";
import ArticleSection from "./ArticlesSection.vue";
import FutureSection from "./FutureSection.vue";
import VideoSection from "./VideoSection.vue";
import OpportunitiesSection from "./OpportunitiesSection.vue";
import NewFooter from "./NewFooter.vue";
import { routeToWebRegister } from "../../helpers/helper";
import { mapActions } from "vuex";

export default {
  components: {
    HomeSection,
    RegisterSection,
    ArticleSection,
    FutureSection,
    VideoSection,
    OpportunitiesSection,
    NewFooter
  },
  methods: {
    ...mapActions(["scrollTop"]),
    goToRegister() {
      routeToWebRegister(this.$router);
    },
  },
  mounted() {
    this.scrollTop();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
