var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"main px-4"},[_vm._m(0),_c('div',{staticClass:"mb-5"},[_c('h1',{staticClass:"f-secondary text-gradient mb-5"},[_vm._v("Latest Roles")]),_c('div',{staticClass:"col-12 row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('div',{staticClass:"card bg-primary-50 text-white col-12 col-md-8"},[_c('a',{staticClass:"text-white text-decoration-none",attrs:{"href":`/public/job-board/18?job_id=1395`}},[_vm._m(1)])]),_c('div',{staticClass:"card bg-primary-50 text-white col-12 col-md-8"},[_c('a',{staticClass:"text-white text-decoration-none",attrs:{"href":`/public/job-board/18?job_id=1249`}},[_vm._m(2)])]),_c('div',{staticClass:"card bg-primary-50 text-white col-12 col-md-8"},[_c('a',{staticClass:"text-white text-decoration-none",attrs:{"href":`/public/job-board/18?job_id=1245`}},[_vm._m(3)])])]),_vm._m(4)])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center container"},[_c('h5',{staticClass:"text-second text-uppercase"},[_vm._v("Opportunities")]),_c('h1',{staticClass:"f-secondary font-40 p-2 text-gradient"},[_vm._v(" Your next role is waiting ")]),_c('p',[_vm._v(" Explore our latest opportunities and start building the best career in health and social care. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"f-secondary font-20 card-title"},[_vm._v("Test")]),_vm._v(" Dubai, United Arab Emirates ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"f-secondary font-20 card-title"},[_vm._v(" Aesthetic Nurse ")]),_vm._v(" Dubai, United Arab Emirates ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"f-secondary font-20 card-title"},[_vm._v(" Paediatric Nephrologist ")]),_vm._v(" Dubai, United Arab Emirates ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-12"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../../public/img/stm/opportunity.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-5"},[_c('p',{staticClass:"f-secondary text-dark-primary bold font-30"},[_vm._v(" Our global health partners ")]),_c('div',{staticClass:"col-12 row gap-4"},[_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo1.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo2.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo3.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo4.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo5.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo6.png")}})]),_c('div',{staticClass:"col-12 row gap-4"},[_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo6.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo7.png")}}),_c('img',{staticClass:"col-6 col-sm-4 col-md-3 col-lg-2 mb-4",attrs:{"src":require("../../../public/img/stm/logo1.png")}})])])
}]

export { render, staticRenderFns }