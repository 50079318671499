<template>
  <div>
    <section class="main px-4">
      <!-- 1 -->
      <div class="text-center container">
        <h5 class="text-second text-uppercase">Opportunities</h5>
        <h1 class="f-secondary font-40 p-2 text-gradient">
          Your next role is waiting
        </h1>
        <p>
          Explore our latest opportunities and start building the best career in
          health and social care.
        </p>
      </div>
      <!-- 2 -->
      <div class="mb-5">
        <h1 class="f-secondary text-gradient mb-5">Latest Roles</h1>
        <div class="col-12 row">
          <div class="col-md-6 col-12">
            <div class="card bg-primary-50 text-white col-12 col-md-8">
              <a
                class="text-white text-decoration-none"
                :href="`/public/job-board/18?job_id=1395`"
              >
                <div class="card-body">
                  <h5 class="f-secondary font-20 card-title">Test</h5>
                  Dubai, United Arab Emirates
                </div>
              </a>
            </div>
            <div class="card bg-primary-50 text-white col-12 col-md-8">
              <a
                class="text-white text-decoration-none"
                :href="`/public/job-board/18?job_id=1249`"
              >
                <div class="card-body">
                  <h5 class="f-secondary font-20 card-title">
                    Aesthetic Nurse
                  </h5>
                  Dubai, United Arab Emirates
                </div>
              </a>
            </div>
            <div class="card bg-primary-50 text-white col-12 col-md-8">
              <a
                class="text-white text-decoration-none"
                :href="`/public/job-board/18?job_id=1245`"
              >
                <div class="card-body">
                  <h5 class="f-secondary font-20 card-title">
                    Paediatric Nephrologist
                  </h5>
                  Dubai, United Arab Emirates
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <img
              class="img-fluid"
              src="../../../public/img/stm/opportunity.png"
            />
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="text-center mb-5">
        <p class="f-secondary text-dark-primary bold font-30">
          Our global health partners
        </p>
        <div class="col-12 row gap-4">
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo1.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo2.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo3.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo4.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo5.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo6.png"
          />
        </div>
        <div class="col-12 row gap-4">
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo6.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo7.png"
          />
          <img
            class="col-6 col-sm-4 col-md-3 col-lg-2 mb-4"
            src="../../../public/img/stm/logo1.png"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      domain: window.location.hostname,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
